import { useEffect, useState } from "react";
import { doc, getDoc, setDoc, arrayUnion, onSnapshot, arrayRemove } from "firebase/firestore";
import { db } from "../../firebase";
import ProductSelector from "./ProductSelector";

import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Stack, Tooltip } from "@mui/material";
import CopyToClipboardButton from "../utils/CopyToClipboardButton";

const StockedProducts = ({regionCode}) => {
  const [stockedProducts, setStockedProducts] = useState();

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "config/stocked-products"), (doc) => {
      setStockedProducts(doc.data()?.region?.[regionCode]);
    })
    return unsubscribe;
  }, []);

  const handleAddProduct = async (product) => {
    if (!product) {
      return;
    }
    const docRef = doc(db, 'config/stocked-products');
    const docSnapshot = await getDoc(docRef);
    const docData = docSnapshot.data();
    if (docData?.region?.[regionCode]?.find(p => p.id === product.id)) {
        console.log(`Product ${product.id} already exists in stocked-products collection`);
        return;
    }
    await setDoc(docRef, { region: { [regionCode]: arrayUnion(product) }}, {merge: true});
  }

  const handleRemoveProduct = async (product) => {
    const docRef = doc(db, 'config/stocked-products');
    const docSnapshot = await getDoc(docRef);
    const docData = docSnapshot.data();
    if (!docData?.region?.[regionCode]?.find(p => p.id === product.id)) {
        console.log(`Product ${product.id} is not in stocked-products collection`);
        return;
    }
    await setDoc(docRef, { region: { [regionCode]: arrayRemove(product) }}, {merge: true});
  }

  return (
    <>
    <ProductSelector onSelectProduct={handleAddProduct} />
    <Grid container spacing={1} pt={1}>
      {stockedProducts && stockedProducts.map((product) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
        <Tooltip arrow title={<Typography fontSize={12}>{product.id} <CopyToClipboardButton size="small" text={product.id} fontSize="inherit" /></Typography>}>
          <Box 
            display="flex" 
            alignItems="center" 
            padding={1} 
            border={1} 
            borderColor="grey.800" 
            borderRadius={1}
          >
            <img 
              src={product.image} 
              alt={product.title} 
              style={{ width: 50, height: 50, objectFit: 'contain', background: 'white', marginRight: 8 }} 
            />
            <Stack style={{flexGrow: 1}}>
              <Typography variant="body2" style={{ fontSize: '0.9rem' }}>
                {product.title}
              </Typography>
              <Typography variant="caption" color={'GrayText'}>{product.handle}</Typography>
            </Stack>
            <IconButton onClick={() => handleRemoveProduct(product)} size="small">
              <DeleteIcon />
            </IconButton>
          </Box>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
    </>
  )
}

export default StockedProducts;
