// Helper function to extract style from SKU
const cloakRegion = (sku) => {
  // Regex to match the style part (ignores the last two parts: region and optional size)
  const match =  sku.match(/(.*)-([A-Z]{2})(?:-(.+))?$/);

  if (!match) {
      return sku
  }

  return match[1] + '-[XX]' + (match[3] ? `-${match[3]}` : "");
}

// Helper function to extract style from SKU
const uncloakRegion = (cloakedSku, regionCode) => {
  // Regex to match the style part (ignores the last two parts: region and optional size)
  return cloakedSku.replace('[XX]', regionCode.toUpperCase());
}

export {
  cloakRegion,
  uncloakRegion,
}