import qrCode from 'qrcode';
import { createCanvas, loadImage } from "canvas";
import { jsPDF } from "jspdf";

// Alexandre's labels size: 60 x 40 mm @ 203 DPI

/**
 * 
 * @param {Object[]} labelItems An array of labelitems to generate
 * @param {string} labelItems[].sku
 * @param {string} labelItems[].barcode
 * @param {string} labelItems[].regionCode - The 2 characters shipping region code (ex: 'US')
 * @param {string} labelItems[].lot - The number of the stock or production order (ex: 2412S)
 * @param {string?} labelItems[].ref - The digit portion of the order name if any (ex: '1234' in '#W1234')
 * @param {'stock'|'production'} templateName The name of the template to use
 * @returns {Promise<jsPDF>} The jsPDF file containing the labels
 */
export const generateLabels = async (labelItems, templateName) => {
  let t;
  switch (templateName) {
    case 'production':
      t = new ProductionTemplate();
      break;
    default:
      throw new Error("Invalid templateName");
  }
  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [t.w, t.h],
    compress: true,
  });

  // Get logo
  const logo = await loadImage('logo.png');

  let firstPage = true;
  for (const {sku, regionCode, lot, ref} of labelItems) {
    await new Promise(resolve => setTimeout(resolve, 0)); // To push back the expensive process to the end of the queue to allow react to update UI while completing the task.
    // Create barcode
    const barcodeCanvas = await qrCode.toCanvas(sku, {
      errorCorrectionLevel: 'H',
      margin: 0,
      width: t.toPx(t.barcodeHeight)
    })

    const dataUrl = t.label(barcodeCanvas, logo, sku, regionCode, lot, ref);

    if (!firstPage) {
      pdf.addPage({orientation: "portrait", format: [t.w, t.h]});
    }
    pdf.addImage(dataUrl, 'PNG', 0, -t.h, t.w, t.h, undefined, 'FAST', -90);
    firstPage = false;
  }
  return pdf;
}

/**
 * Places an image on a canvas, according to the options provided.
 * For one dimension (ie: horizontal) if none of the values (left, right)
 * are provided, the placement will be centered, between the margins;
 * If one value is provided, the placement will be aligned to that side;
 * If both values are provided, the image will be stretched between those
 * values, overriding the width.
 * @param {canvas} canvas 
 * @param {canvas} image 
 * @param {Object} options
 * @param {number|'auto'} [options.top=auto]
 * @param {number|'auto'} [options.right=auto]
 * @param {number|'auto'} [options.bottom=auto]
 * @param {number|'auto'} [options.left=auto]
 * @param {number} [options.width]
 * @param {number} [options.height]
 * @param {number} [options.marginTop=0]
 * @param {number} [options.marginRight=0]
 * @param {number} [options.marginBottom=0]
 * @param {number} [options.marginLeft=0]
 * @param {boolean} [options.preventStretch=false]
 */
const placeImage = (
  canvas, 
  image, 
  { 
    top = 'auto', 
    right = 'auto', 
    bottom='auto', 
    left='auto', 
    width,
    height,
    marginTop=0, 
    marginRight=0, 
    marginBottom=0, 
    marginLeft=0,
    preventStretch=false,
  }) => {
  const ctx = canvas.getContext('2d');

  let x, y

  // Proportionally scale image if there is a width xor height
  const imgRatio = image.height / image.width;
  if (width && !height) {
    height = imgRatio * width;
  } else if (height && !width) {
    width = height / imgRatio;
  } else {
    width = image.width;
    height = image.height;
  }
  
  // Apply margins
  if (top !== 'auto') top += marginTop;
  if (right !== 'auto') right = canvas.width - marginRight - right;
  if (bottom !== 'auto') bottom = canvas.height - marginBottom - bottom;
  if (left !== 'auto') left += marginLeft;

  // Handle 'auto' and number values for top and bottom
  if (top === 'auto' && bottom === 'auto') {
    y = (canvas.height - height) / 2;
  } else if (top === 'auto') {
    y = bottom - height;
  } else if (bottom === 'auto') {
    y = top;
  } else {
    y = top;
    if (!preventStretch) {
      // Stretch the image between top and bottom
      height = bottom - top;
    } else {
      // Center the image between top and bottom
      y += (bottom - top - height) / 2;
    }
    height = bottom - top;
  }
  
  // Handle 'auto' and number values for left and right
  if (left === 'auto' && right === 'auto') {
    x = (canvas.width - width) / 2;
  } else if (left === 'auto') {
    x = right - width;
  } else if (right === 'auto') {
    x = left;
  } else {
    x = left;
    if (!preventStretch) {  
      // Stretch the image between
      width = right - left;
    } else {
      // Center the image between left and right
      x += (right - left - width) / 2;
    }
  }
  
  // Draw the image on the canvas
  ctx.drawImage(image, x, y, width, height);
}

/**
 * Places a text element on a canvas. If the 
 * For one dimension (ie: horizontal) if none of the values (left, right)
 * are provided, the placement will be centered, between the margins;
 * If one value is provided, the placement will be aligned to that side;
 * If both values are provided, the text will be aligned to the top for the
 * vertical dimension and aligned left for the horizontal dimension.
 * @param {canvas} canvas 
 * @param {string} text 
 * @param {Object} options
 * @param {string} font - Ex: "bold 20px sans-serif"
 * @param {number|'auto'} [options.top=auto]
 * @param {number|'auto'} [options.right=auto]
 * @param {number|'auto'} [options.bottom=auto]
 * @param {number|'auto'} [options.left=auto]
 * @param {number} [options.maxWidth] - The maximum width of the text element. If not provided, the text will be constraint to margins or canvas boundary.
 * @param {number} [options.marginTop=0]
 * @param {number} [options.marginRight=0]
 * @param {number} [options.marginBottom=0]
 * @param {number} [options.marginLeft=0]
 */
function placeText(
  canvas, 
  text, 
  { 
    font, 
    top="auto", 
    right="auto", 
    bottom="auto", 
    left="auto", 
    maxWidth,
    marginTop=0, 
    marginRight=0, 
    marginBottom=0, 
    marginLeft=0,
  }) {
  const ctx = canvas.getContext('2d');
  let x, y;
  
  // Set the font
  ctx.font = font;
  
  // Apply margins
  if (top !== 'auto') top += marginTop;
  if (right !== 'auto') right = canvas.width - marginRight - right;
  if (bottom !== 'auto') bottom = canvas.height - marginBottom - bottom;
  if (left !== 'auto') left += marginLeft;

  // Apply margins to max width
  maxWidth =  maxWidth || canvas.width - marginRight - marginLeft;
  
  // Handle 'auto' and number values for top and bottom
  if (top === 'auto' && bottom === 'auto') {
    y = canvas.height / 2;
    ctx.textBaseline = 'middle';
  } else if (top === 'auto') {
    y = bottom;
    ctx.textBaseline = 'bottom';
  } else if (bottom === 'auto') {
    y = top;
    ctx.textBaseline = 'top';
  } else {
    y = top + (bottom - top) / 2;  // Center the text vertically
    ctx.textBaseline = 'middle';
  }

  // Handle 'auto' and number values for left and right
  if (left === 'auto' && right === 'auto') {
    x = (canvas.width - marginRight + marginLeft) / 2;
    ctx.textAlign = 'center';
  } else if (left === 'auto') {
    x = right;
    ctx.textAlign = 'right';
  } else if (right === 'auto') {
    x = left;
    ctx.textAlign = 'left';
  } else {
    x = left + (right - left) / 2;  // Center the text horizontally
    ctx.textAlign = 'center';
    maxWidth = right - left;  // Enforce maxWidth to be within left-right values
  }
  
  // Draw the text on the canvas
  ctx.fillText(text, x, y, maxWidth);
}

class ProductionTemplate {
  ratio = 11.811; // pixel/mm
  w = 89;
  h = 28;
  margin = 4;
  barcodeHeight = 20;
  toPx = (mm) => Math.round(mm * this.ratio);
  label = (barcode, logo, sku, regionCode, lot, ref) => {
    const marginPx = this.toPx(this.margin);
    const margins = {
      marginTop: marginPx,
      marginRight: marginPx,
      marginBottom: marginPx,
      marginLeft: marginPx,
    }
    const canvas = createCanvas(this.toPx(this.w), this.toPx(this.h), 'pdf');
    placeImage(canvas, logo, {
      top: 0,
      left: 0,
      height: this.toPx(this.h - this.margin * 3 - 3.6),
      ...margins,
    });
    placeImage(canvas, barcode, {
      bottom: 0,
      right: 0,
      preventStretch: true,
      ...margins,
    });
    placeText(canvas, sku, {
      font: `${this.toPx(3.6)}px sans-serif`,
      bottom: 0,
      left: 0,
      maxWidth: this.toPx(this.w - this.margin * 3 - this.barcodeHeight),
      ...margins,
    });
    placeText(canvas, regionCode, {
      font: `${this.toPx(3.6)}px sans-serif`,
      top: 0,
      right: this.toPx(this.barcodeHeight + this.margin),
      ...margins,
    });
    placeText(canvas, `LOT: ${lot}`, {
      font: `${this.toPx(3.6)}px sans-serif`,
      top: this.toPx(3.6 * 1.3),
      right: this.toPx(this.barcodeHeight + this.margin),
      ...margins,
    });
    if (ref) {
      placeText(canvas, `REF: ${ref}`, {
        font: `${this.toPx(3)}px sans-serif`,
        top: this.toPx((3.6 + 3.6) * 1.3),
        right: this.toPx(this.barcodeHeight + this.margin),
        ...margins,
      });
    }
    return canvas.toDataURL("application/pdf");
  };
}