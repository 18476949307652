import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { generateLabels } from '../../utils/generateLabels';
import LoadingButton from '../LoadingButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';


function DownloadLabelsDialog({lineItems, showDialog, closeDialog}) {
  const [printItems, setPrintItems] = useState([]);
  const [newChecked, setNewChecked] = useState(true);
  const [reservedChecked, setReservedChecked] = useState(true);
  const [sentChecked, setSentChecked] = useState(false);
  const [newCount, setNewCount] = useState(true);
  const [reservedCount, setReservedCount] = useState(true);
  const [sentCount, setSentCount] = useState(false);
  const [lot, setLot] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showDialog) {
      setNewChecked(true);
      setReservedChecked(true);
      setSentChecked(false);
      resetReference();
    }
  }, [showDialog]);

  useEffect(() => {
    const newPrintItems = [];
    let newNewCount = 0;
    let newReservedCount = 0;
    let newSentCount = 0;

    for (const {
      requiredQty = 0,
      reservedQty = 0, 
      sentQty = 0,
      sku,
      barcode,
      shippingCountryCode,
      orderName,
    } of lineItems) {
      // New qty
      for (let i = 0; i < requiredQty - (reservedQty + sentQty); i++) {
        if (newChecked) {
          newPrintItems.push({ sku, barcode, regionCode: shippingCountryCode, lot, ref: orderName.match(/\d+$/)?.[0] });
        }
        newNewCount++;
      }
      // Reserved qty
      for (let i = 0; i < reservedQty; i++) {
        if (reservedChecked) {
          newPrintItems.push({ sku, barcode, regionCode: shippingCountryCode, ref: orderName.match(/\d+$/)?.[0] });
        }
        newReservedCount++;
      }
      // Sent qty
      for (let i = 0; i < sentQty; i++) {
        if (sentChecked) {
          newPrintItems.push({ sku, barcode, regionCode: shippingCountryCode, ref: orderName.match(/\d+$/)?.[0] });
        }
        newSentCount++;
      }
    }

    setNewCount(newNewCount);
    setReservedCount(newReservedCount);
    setSentCount(newSentCount);
    setPrintItems(newPrintItems);

  }, [lineItems, newChecked, reservedChecked, sentChecked, lot]);

  const handleToggleNew = () => {
    setNewChecked(!newChecked);
  }

  const handleToggleReserved = () => {
    setReservedChecked(!reservedChecked);
  }

  const handleToggleSent = () => {
      setSentChecked(!sentChecked);
  }

  const handleChange = (e) => {
    setLot(e.target.value);
  }

  const resetReference = (e) => {
    // reset the reference if it's blank
    if (lot === '') {
      const today = new Date();
      const thisMonthDate = new Date(today.getFullYear(), today.getMonth(), 1); // this month
      const thisMonthStr = new Intl.DateTimeFormat('en-CA', {month: '2-digit'}).format(thisMonthDate).toUpperCase();
      setLot(`${thisMonthDate.getFullYear().toString().substr(-2)}${thisMonthStr}S`);
    }
  }

  const handleDownloadLabels = async () => {
    setLoading(true);
    const pdf = await generateLabels(printItems.sort((a, b) => parseInt(a.ref) - parseInt(b.ref)), "production");
    pdf.save(`Etiquettes_${formattedTimestamp()}.pdf`);
    setLoading(false);
  }

  function formattedTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  }

  return (
    <Dialog
      open={showDialog}
      onClose={closeDialog}
      maxWidth={'full'}
    >
      <DialogTitle>Download Labels</DialogTitle>
      <DialogContent>
      <p>Make sure to assign the correct order reference <Tooltip title="This is the stock order number that will be assigned to these labels (ex: 2501S). This is calculated based on the current month but it could be wrong if printing labels at the beginning of a new month, right before shipping."><InfoIcon fontSize='xs' /></Tooltip>.</p>
      <TextField id="reference" label="Reference" variant="outlined" value={lot} onChange={handleChange} onBlur={resetReference} />
      <p>Select labels to download.</p>
      <FormGroup>
        <FormControlLabel disabled={!newCount} control={<Checkbox checked={newChecked} onChange={handleToggleNew} />} label={`New Items (${newCount})`} />
        <FormControlLabel disabled={!reservedCount} control={<Checkbox checked={reservedChecked} onChange={handleToggleReserved} color="success" />} label={`Reserved Items (${reservedCount})`} />
        <FormControlLabel disabled={!sentCount} control={<Checkbox checked={sentChecked} onChange={handleToggleSent} color="secondary" />} label={`Sent Items (${sentCount})`} />
      </FormGroup>
      </DialogContent>
      <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button><LoadingButton startIcon={<DownloadIcon />} variant="contained" loading={loading} onClick={handleDownloadLabels} disabled={!printItems.length}>Download ({printItems.length})</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadLabelsDialog;