import { useEffect, useState } from 'react';
import {db, functions} from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { collection, doc, onSnapshot, query, orderBy, limit } from 'firebase/firestore';
import moment from 'moment-timezone';
import sizeof from 'firestore-size';
import extractStyle from '../../utils/extractStyle.js';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import OrderCreateModal from './OrderCreateModal.js';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Unstable_Grid2';
import SalesStats from './SalesStats.js';
import RegionTabs from './RegionTabs.js';

const ProdOrder = ({ setTitle, setLoading, setMenuItems }) => {
    const [productAliases, setProductAliases] = useState();
    const [salesData, setSalesData] = useState();
    const [inventoryData, setInventoryData] = useState();
    const [lastPeriodsSales, setLastPeriodsSales] = useState({});
    const [requiredQties, setRequiredQties] = useState();
    const [usingLastPeriods, setUsingLastPeriods] = useState({}); // A map of each sku and whether they calculated the required qty using the recent sales or not.
    const [expectedProductionSales, setExpectedProductionSales] = useState();
    const [expectedRestockingSales, setExpectedRestockingSales] = useState();
    const [expectedLastPeriodsSales, setExpectedLastPeriodsSales] = useState();
    const [orderQties, setOrderQties] = useState();
    const [isStoreOrderable, setIsStoreOrderable] = useState();
    const [isStyleOrderable, setIsStyleOrderable] = useState();
    const [restockingTime, setRestockingInterval] = useState(3); // Nb months between the orders
    const [restockingIntervalDates, setRestockingIntervalDates] = useState({}); // The dates last year of the next restocking interval
    const [restockingSales, setRestockingSales] = useState(); // The sales per product last year between the next restocking inverval dates
    const [productionTime, setProductionTime] = useState(2); // Nb months from order to delivery
    const [productionDates, setProductionDates] = useState({}); // The dates last year of the next production time
    const [productionSales, setProductionSales] = useState(); // The sales per product last year during the next the production time
    const [customRequired, setCustomRequired] = useState({});
    const [barcodes, setBarcodes] = useState();
    const [orderAnchorEl, setOrderAnchorEl] = useState(null);
    const [modalContent, setModalContent] = useState();
    const [sizeRatios, setSizeRatios] = useState();
    const [lastYearGrowthRate, setLastYearGrowhRate] = useState();

    const minQtyPerStore = 70;
    const minQtyPerStyle = 16;

    moment.tz.setDefault("America/Toronto");

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        maxHeight: '75vh',
        overflow: 'auto',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    useEffect(() => {
        // Subscribe to aliases
        const unsubscribe = onSnapshot(doc(db, 'config', 'product-aliases'), (snapshot) => {
            setProductAliases(snapshot.data());
        });
        return unsubscribe;
    }, []);

    useEffect(() => {
        const menuItems = <>
            <IconButton
                aria-label="download"
                aria-controls={orderMenuId}
                aria-haspopup="true"
                onClick={handleOrderMenuOpen}
                color="inherit"
            >
                <MoreHorizIcon />
            </IconButton>
        </>
        setMenuItems(menuItems);
    }, [setMenuItems])

    const handleModalOpen = (modalContent) => setModalContent(modalContent);
    const handleModalClose = () => setModalContent(undefined);

    /**
     * Get inventory data
     */
    useEffect(() => {
        setTitle('FABRIQUE');
        setLoading(true);
        const q = query(collection(db, 'stats-inventory'), orderBy('createdAt', 'desc'), limit(1));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const docs = querySnapshot.docs
            if (querySnapshot.empty || docs[0].data().createdAt < Date.now() - 24 * 60 * 60 * 1000) {
                // View doesn't exist or is more than 24 hours old
                console.debug("Requesting inventory update")
                const requestUpdate = httpsCallable(functions, 'fabrique-getInventory');
                requestUpdate();
            } else {
                const data = querySnapshot.docs[0].data();
                console.debug(`stats-inventory size: ${sizeof(data)}B (${(sizeof(data)/(1024*1024)*100).toFixed(1)}%)`);
                setInventoryData(data);
            }
            setLoading(false);
        });
        return unsubscribe;
    }, []);

    /**
     * Upon receiving the inventoryData, salesData, and productAliases, calculate the required quantity of each SKU per store
     */
    useEffect(() => {
        if (!inventoryData || !salesData || !productAliases) return;
        console.debug('Calculating required quantity of each SKU...');
        const nextIsStoreOrderable = {};
        const nextRequiredQties = {};
        const nextExpectedProductionSales = {};
        const nextProductionSales = {};
        const nextExpectedLastPeriodsSales = {};
        const nextLastPeriodsSales = {};
        // Corresponds to the interval between the current date (salesData.createdAt) and until the date the production is delivered.
        const nextProductionDates = {
            start: moment(salesData.createdAt).startOf("day").valueOf(),
            end: moment(salesData.createdAt).add(productionTime, "months").subtract(1, "day").startOf("day").valueOf(),
        }
        const nextExpectedRestockingSales = {};
        const nextRestockingSales = {};
        // Corresponds to the interval between the date the production is delivered and until the date the next production is delivered.
        // The stock needs to cover that entire period if we don't want to run out of stock.
        const nextRestockingIntervalDates = {
            start: moment(salesData.createdAt).add(productionTime, "months").startOf("day").valueOf(),
            end: moment(salesData.createdAt).add(productionTime + restockingTime, "months").subtract(1, "day").startOf("day").valueOf(),
        }
        const nextUsingLastPeriods = {};
        const nextBarcodes = {};
        const growthRate = salesData.lastYear.compareAt === 0 ? 1 : (salesData.lastYear.total / salesData.lastYear.compareAt); // if no lastYear data, division by 0 will be 'NaN' or 'Infinity'

        const newSizeRatios = salesData.allTimeBySize.reduce((acc, {size, qty}) => {
            acc[size] = (qty / salesData.allTime) || 0;
            return acc;
        }, {})

        for (const regionCode of Object.keys(inventoryData.region)) {
            // For each region
            for (const {sku, size, barcode, productId, available=0, incoming=0} of inventoryData.region[regionCode]) {
                // For each variant
                nextBarcodes[sku] = barcode;
                const ratioOfSize = newSizeRatios[size];
                
                /*
                    ** CALCULATE THE REQUIRED QTY **

                    The required qty can be roughly thought of:

                    [requiredQty] = [expectedSalesProdInterval] + [expectedSalesRestockInterval] + [buffer] - [currentInventory] - [incomingInventory]

                    If we have the data available, we calculate the expected sales from the same period of the previous year's data.
                    For example, if the prodInterval is Dec 10, 2024 to Jan 10, 2025, the expectedSaleProdInterval will be based on
                    the sales between Dec 10, 2023 and Jan 10, 2024. We calculate those sales per product and we multiply by last year's
                    growth rate. Then, we redistribute the total qty by size:

                    [expectedSalesProdInterval] = [prodIntervalMinusOneYearSales] * [previousYearGrowthRate]
                */

                // Calculate sales of latest periods for the same duration as the restocking and production time as a fallback if the prevYear is too low.
                let salesLastPeriods = 0;
                
                for (let i = 13 - productionTime - restockingTime; i <= 12; i++) {
                    salesLastPeriods += salesData.lastYearByProductByRegionByPeriod?.[regionCode]?.[productId]?.[i] || 0;

                    // Add aliases sales
                    for (const aliasId of productAliases[productId] || []) {
                        if (salesData.lastYearByProductByRegionByPeriod[regionCode]?.[aliasId]?.[i] > 0) {
                            console.debug(`salesLastPeriods: adding ${salesData.lastYearByProductByRegionByPeriod[regionCode]?.[aliasId]?.[i]} qty from alias ${aliasId}`)
                        }
                        salesLastPeriods += (salesData.lastYearByProductByRegionByPeriod[regionCode]?.[aliasId]?.[i] || 0);
                    }
                }

                if (!nextLastPeriodsSales[sku]) {
                    nextLastPeriodsSales[sku] = {};
                }
                nextLastPeriodsSales[sku][regionCode] = salesLastPeriods;
                
                if (!nextExpectedLastPeriodsSales[sku]) {
                    nextExpectedLastPeriodsSales[sku] = {};
                }
                nextExpectedLastPeriodsSales[sku][regionCode] = Math.round(salesLastPeriods * ratioOfSize);

                // Expected sales - Production interval
                
                // Sales for the past periods for the product x ratio of size x regional increase in sales from last year
                // Option 1: Sales for the production interval minus one year.
                let salesProdIntervalPrevYear = 0;
                for (let i = 1; i <= productionTime; i++) { 
                    /*
                        Each period. 
                        Note: the periods correstponds to the number of months from the stats-view createdAt date minus 1 year.
                        Ex: if stats-view was created on Dec 10, 2024, the period 1 will correspond to Dec 10, 2023 to Jan 9 2024.
                    */
                    salesProdIntervalPrevYear += (salesData.lastYearByProductByRegionByPeriod?.[regionCode]?.[productId]?.[i] || 0); // Total sales for that period
                    // Include sales from aliases
                    for (const aliasId of productAliases[productId] || []) {
                        if (salesData.lastYearByProductByRegionByPeriod[regionCode]?.[aliasId]?.[i] > 0) {
                            console.debug(`salesProdIntervalPrevYear: adding ${(salesData.lastYearByProductByRegionByPeriod[regionCode]?.[aliasId]?.[i] || 0)} qty from alias ${aliasId}`)
                        }
                        salesProdIntervalPrevYear += (salesData.lastYearByProductByRegionByPeriod[regionCode]?.[aliasId]?.[i] || 0);
                    }
                }
                if (!nextProductionSales[sku]) {
                    nextProductionSales[sku] = {};
                }
                nextProductionSales[sku][regionCode] = salesProdIntervalPrevYear;
                let projectedProductionSales = salesProdIntervalPrevYear * growthRate;

                if (!nextExpectedProductionSales[sku]) {
                    nextExpectedProductionSales[sku] = {};
                }
                nextExpectedProductionSales[sku][regionCode] = Math.round(projectedProductionSales * ratioOfSize);

                // Expected sales - Restocking interval
                
                // Sales for the past periods for the product x ratio of size x regional increase in sales from last year
                // Option 1: Sales for the restocking interval minus one year.
            
                if (productionTime + restockingTime > 12) {
                    throw new Error(`The data required to calculate the baseInventory doesn't exist. It requires to analyse past sales that are in the future. Reduce the forcastThreshold or the inventory security buffer.`);
                }

                let salesRestockIntervalPrevYear = 0;
                for (let i = 1 + productionTime; i <= productionTime + restockingTime; i++) { // Each period of the restocking interval
                    salesRestockIntervalPrevYear += (salesData.lastYearByProductByRegionByPeriod[regionCode]?.[productId]?.[i] || 0); // Total sales for that period
                    // Include sales from aliases
                    for (const aliasId of productAliases[productId] || []) {
                        if (salesData.lastYearByProductByRegionByPeriod[regionCode]?.[aliasId]?.[i] > 0) {
                            console.debug(`salesRestockIntervalPrevYear: adding ${(salesData.lastYearByProductByRegionByPeriod[regionCode]?.[aliasId]?.[i] || 0)} qty from alias ${aliasId}`)
                        }
                        salesRestockIntervalPrevYear += (salesData.lastYearByProductByRegionByPeriod[regionCode]?.[aliasId]?.[i] || 0);
                    }
                }
                if (!nextRestockingSales[sku]) {
                    nextRestockingSales[sku] = {};
                }
                nextRestockingSales[sku][regionCode] = salesRestockIntervalPrevYear;
                let projectedRestockingSales = salesRestockIntervalPrevYear * growthRate;

                if (!nextExpectedRestockingSales[sku]) {
                    nextExpectedRestockingSales[sku] = {};
                }
                nextExpectedRestockingSales[sku][regionCode] = Math.round(projectedRestockingSales * ratioOfSize);

                /* 
                    Option 2: If the sales from the production interval minus one year are too low, the sales from the most recent periods
                    will be used. 
                    Ex: If the stats-view createdAt date is Dec 10 2024, and production is 1 month and restocking 2 months we will use the 
                    sales from periods 10, 11, 12.
                    This is less ideal because it doesn't take into account the demand fluctuation throughout the year (ex: Black Friday).
                */
                const totalSalesPrevYear = salesProdIntervalPrevYear + salesRestockIntervalPrevYear;
                const projectedTotalSales = projectedProductionSales + projectedRestockingSales;
                
                if (!nextUsingLastPeriods[sku]) {
                    nextUsingLastPeriods[sku] = {};
                }
                if (salesLastPeriods > projectedTotalSales && totalSalesPrevYear < productionTime + restockingTime) {
                    // We didn't have at least 1 sale per month last year and the projections based of the past months is larger than the projection based on last year 
                    // We prefer last year data for the accurate months if there are enough sales
                    // If there are less than 1 unit sold per month in the previous year AND the recent sales are higher than the previous
                    // year sales, we use the recent sales. It helps if a product is recently picking up interest.
                    nextUsingLastPeriods[sku][regionCode] = true;
                }
                
                // Calculate required inventory to order. We want to ensure a minimum buffer qty of 2 so we add 2 to the expected sales if they are less than 2
                let required = nextUsingLastPeriods[sku][regionCode] ? 
                    Math.max(Math.ceil((nextExpectedLastPeriodsSales[sku][regionCode] - (available - 2) - incoming)/2)*2, 0) :
                    Math.max(Math.ceil((nextExpectedRestockingSales[sku][regionCode] + nextExpectedProductionSales[sku][regionCode] - (available - 2) - incoming)/2)*2, 0);

                if (nextRequiredQties[sku] === undefined) {
                    nextRequiredQties[sku] = {};
                }
                nextRequiredQties[sku][regionCode] = required;
            }
        }

        setSizeRatios(newSizeRatios);
        setLastYearGrowhRate(growthRate);
        setLastPeriodsSales(nextLastPeriodsSales);
        setExpectedLastPeriodsSales(nextExpectedLastPeriodsSales);
        setBarcodes(nextBarcodes);
        setRequiredQties(nextRequiredQties);
        setExpectedProductionSales(nextExpectedProductionSales);
        setProductionSales(nextProductionSales);
        setProductionDates(nextProductionDates);
        setExpectedRestockingSales(nextExpectedRestockingSales);
        setRestockingSales(nextRestockingSales);
        setRestockingIntervalDates(nextRestockingIntervalDates);
        setIsStoreOrderable(nextIsStoreOrderable);
        setUsingLastPeriods(nextUsingLastPeriods);
    }, [inventoryData, salesData, productionTime, restockingTime]);

    /** Upon receiving new requiredQties, sum up the total qty per style to identify which styles have enough qty to start production */
    useEffect(() => {
        // TODO: Move All calculations related to requiredQty in the same block to prevent infinite loop.
        if (!requiredQties) return;
        console.debug(`Calculating orderableStores and orderableStyles...`);

        let orderableStores = {};
        let orderableStyles = {};
        let lastOrderableStores = {};
        let lastOrderableStyles = {};
        let iterationCount = 0;

        do {
            // Assume changes to re-evaluate conditions
            lastOrderableStores = { ...orderableStores };
            lastOrderableStyles = { ...orderableStyles };
    
            // Reset for recalculation
            let storeTotals = {};
            let styleTotals = {};

            // Calculate totals by store and by style
            Object.entries(requiredQties).forEach(([sku, quantities]) => {
                const style = extractStyle(sku);
    
                Object.entries(quantities).forEach(([store, quantity]) => {
                    if (iterationCount === 0 || lastOrderableStores[store]) {
                        if (customRequired[store]?.[sku] !== undefined) {
                            storeTotals[store] = (storeTotals[store] || 0) + customRequired[store][sku];
                            styleTotals[style] = (styleTotals[style] || 0) + customRequired[store][sku];
                        } else {
                            storeTotals[store] = (storeTotals[store] || 0) + quantity;
                            styleTotals[style] = (styleTotals[style] || 0) + quantity;
                        }
                    }
                });
            });
    
            // Update orderable stores and styles based on calculated totals
            orderableStores = Object.fromEntries(
                Object.entries(storeTotals).map(([store, total]) => [store, total >= minQtyPerStore])
            );
    
            orderableStyles = Object.fromEntries(
                Object.entries(styleTotals).map(([style, total]) => [style, total >= minQtyPerStyle])
            );
    
            iterationCount++;
        } while (
            JSON.stringify(lastOrderableStores) !== JSON.stringify(orderableStores) ||
            JSON.stringify(lastOrderableStyles) !== JSON.stringify(orderableStyles)
        );

        /** Once we have the required quantities, the orderable styles and the orderable stores, calculate the order quantities */
        console.debug('Calculating order quantities...')
        const order = {}
        for (const sku in requiredQties) {
            const baseSku = extractStyle(sku);
            if (!orderableStyles[baseSku]) continue;
            order[sku] = {};
            for (const store in requiredQties[sku]) {
                order[sku][store] = orderableStores[store] ? (customRequired[store]?.[sku] !== undefined ? customRequired[store][sku] : requiredQties[sku][store]) : 0;
            }
        }
        setOrderQties(order);
        setIsStoreOrderable(orderableStores);
        setIsStyleOrderable(orderableStyles);
    }, [requiredQties, customRequired]);

    const handleOrderMenuClose = () => {
        setOrderAnchorEl(null);
    };

    const isOrderMenuOpen = Boolean(orderAnchorEl);

    const handleOrderMenuOpen = (event) => {
        setOrderAnchorEl(event.currentTarget);
    };

    const handleRequiredChanged = (store, newStoreState) => {
        const newCustomRequired = {...customRequired};
        newCustomRequired[store] = newStoreState;
        setCustomRequired(newCustomRequired);
    }

    const orderMenuId = 'account-menu';
    const renderOrderMenu = (
      <Menu
        anchorEl={orderAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={orderMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOrderMenuOpen}
        onClose={handleOrderMenuClose}
        disableScrollLock={true}
      >
        <MenuItem onClick={() => {
            handleModalOpen('create-order');
            handleOrderMenuClose();
        }}>Créer une commande</MenuItem>
        <MenuItem onClick={() => {
            handleModalOpen('create-order-reseller');
            handleOrderMenuClose();
        }}>Créer une commande revendeur</MenuItem>
      </Menu>
    );

    const getModalContent = () => {
        switch (modalContent) {
            case 'create-order':
            case 'create-order-reseller':
                let reseller = false;
                if (modalContent === 'create-order-reseller') {
                    reseller = true;
                }
                return <OrderCreateModal reseller={reseller} initialQties={orderQties} barcodes={barcodes} isStoreOrderable={isStoreOrderable} closeModal={handleModalClose} />
        }
        return null;
    }

    const handleSalesStatsUpdate = (data) => {
        console.debug("salesStats updated");
        console.debug({data});
        setSalesData(data);
    }

    const handleProdTimeChange = (evt) => {
        console.debug("productionTime changed");
        setProductionTime(evt.target.value);
    }

    const handleRestockingIntervalChange = (evt) => {
        console.debug("restockingInterval changed");
        setRestockingInterval(evt.target.value);
    }
      
    function valuetext(value) {
        return `${value}mo`;
    }
    
    return (
        <>
            <Stack direction="column" spacing={2} py={2}>
                <SalesStats onUpdate={handleSalesStatsUpdate} />
                <Divider />
                <Grid container spacing={2} maxWidth={800}>
                    <Grid xs={4}>
                        <Typography id="input-slider" gutterBottom>
                            Production Time:
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Slider
                            aria-label="Production Time"
                            value={productionTime}
                            getAriaValueText={valuetext}
                            valueLabelDisplay="auto"
                            shiftStep={1}
                            step={1}
                            marks
                            min={1}
                            max={3}
                            onChange={handleProdTimeChange}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Typography id="input-slider" gutterBottom>
                            {productionTime} months - {restockingIntervalDates.start && moment(productionDates.start).format("MMM D")} to {moment(productionDates.end).format("MMM D, YYYY")}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography id="input-slider" gutterBottom>
                            Restocking Interval:
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Slider
                            aria-label="Restocking Interval"
                            value={restockingTime}
                            getAriaValueText={valuetext}
                            valueLabelDisplay="auto"
                            shiftStep={1}
                            step={1}
                            marks
                            min={1}
                            max={6}
                            onChange={handleRestockingIntervalChange}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Typography id="input-slider" gutterBottom>
                            {restockingTime} months - {restockingIntervalDates.start && moment(restockingIntervalDates.start).format("MMM D")} to {moment(restockingIntervalDates.end).format("MMM D, YYYY")}
                        </Typography>
                    </Grid>
                </Grid>
                <RegionTabs 
                    isStoreOrderable={isStoreOrderable}
                    isStyleOrderable={isStyleOrderable}
                    inventoryData={inventoryData}
                    expectedRestockingSales={expectedRestockingSales}
                    usingLastPeriods={usingLastPeriods}
                    restockingTime={restockingTime}
                    restockingSales={restockingSales}
                    restockingIntervalDates={restockingIntervalDates}
                    requiredQties={requiredQties}
                    expectedProductionSales={expectedProductionSales}
                    productionTime={productionTime}
                    productionSales={productionSales}
                    productionDates={productionDates}
                    orderQties={orderQties}
                    customRequired={customRequired}
                    expectedLastPeriodsSales={expectedLastPeriodsSales}
                    lastPeriodsSales={lastPeriodsSales}
                    lastYearGrowthRate={lastYearGrowthRate}
                    sizeRatios={sizeRatios}
                    onChangeRequired={handleRequiredChanged}
                />
            </Stack>
            <Modal open={!!modalContent} onClose={handleModalClose}>
                <Box sx={modalStyle}>
                    {getModalContent()}
                </Box>
            </Modal>
            {renderOrderMenu}
        </>
    )
}

export default ProdOrder;