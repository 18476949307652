import { useState, useEffect } from 'react';
import { Box, Stack, TextField, Typography, alpha } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import NoMaxWidthTooltip from '../utils/NoMaxWidthTooltip';

function float(float) {
  return Math.round(float*100)/100;
}

const StatsTable = ({tableData, productionTime, restockingTime, lastYearGrowthRate, customRequired, onChangeRequired}) => {
  const [total, setTotal] = useState();
  const [localCustomRequired, setLocalCustomRequired] = useState({});
  const largeScreen = useMediaQuery('(min-width:1100px)');
  const theme = useTheme();

  useEffect(() => {
    setLocalCustomRequired(customRequired || {});
  }, [customRequired]);
  
  useEffect(() => {
    console.debug('Formatting tableData...')
    console.debug({tableData})
    if (!tableData) return;
    const nextTotal = {
      available: 0,
      incoming: 0,
      expectedSales: 0,
      required: 0,
      order: 0
    };
    // Helper function to extract style from SKU
    const extractStyle = (sku) => sku.split('-').slice(0, -1).join('-');
    const processedStyles = [];
    for (const item of tableData) {
        const style = extractStyle(item.sku);
        if (!processedStyles.includes(style)) {
          // Runs once per product
          processedStyles.push(style);
        }
        nextTotal.expectedSales += item.usingLastPeriods ? item.expectedLastPeriodsSales : item.expectedProductionSales + item.expectedRestockingSales;
        nextTotal.available += item.available;
        nextTotal.incoming += item.incoming;
        nextTotal.expected += item.expected;
        nextTotal.required += item.required;
        nextTotal.order += item.order;
    }
    setTotal(nextTotal);
  }, [tableData]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChangeRequired(localCustomRequired)
    }, 500); // Debounce delay

    return () => clearTimeout(timeout); // Cleanup timeout
  }, [localCustomRequired]);

  const handleInputChange = (sku, value) => {
    // Update local state for immediate feedback
    const newLocalCustomRequired = {...localCustomRequired}
    if (value === '') {
      delete newLocalCustomRequired[sku];
    } else {
      newLocalCustomRequired[sku] = parseInt(value);
    }
    setLocalCustomRequired(newLocalCustomRequired);
  };

  return tableData && total ? (
      <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell align="right">{largeScreen ? "Current Qty" : "Cur"}</TableCell>
            <TableCell align="right">{largeScreen ? "Incoming Qty" : "Inc"}</TableCell>
            <TableCell title="Total expected sales per product during the production and restocking inteval" align="right">{largeScreen ? `Expected Sales (${productionTime + restockingTime} months)` : "ExpS"}</TableCell>
            <TableCell align="right">{largeScreen ? "Required Qty" : "Req"}</TableCell>
            <TableCell align="right">{largeScreen ? "Required Qty Override" : "ReqOvr"}</TableCell>
            <TableCell align="right">{largeScreen ? "Order Qty" : "Ord"}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell sx={{borderBottom: "none", top: 37}} align="left">Total</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.available}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.incoming}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.expectedSales}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.required}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.customRequired}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37, fontWeight: "bold"}} align="right">{total.order}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.sort((a, b) => {
              if (a.sku < b.sku) {
                return -1;
              }
              if (a.sku > b.sku) {
                return 1;
              }
              return 0;
          }).map((row) =>{
            const rowColor = row.orderable ? theme.palette.text.primary : theme.palette.grey[700];
            return (
            <TableRow
              key={row.sku}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, bgColor: (theme) => row.orderable ? theme.palette.success : theme.palette.grey[700]}}
            >
              <TableCell align="left" sx={{color: rowColor}}>{row.sku}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.available}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.incoming}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>
                <NoMaxWidthTooltip arrow title={<Stack direction={'column'} maxWidth={'100%'} gap={1}>

                  <FormulaRow condition={!row.usingLastPeriods}>
                    <Parentheses>
                      <HighlightBox>Last Year Prod Sales</HighlightBox>
                      <Operator operation='addition' />
                      <HighlightBox>Last Year Restock Sales</HighlightBox>
                    </Parentheses>
                    <Operator operation='multiplication' />
                    <HighlightBox>Last Year Growh Rate</HighlightBox>
                    <Operator operation='multiplication' />
                    <HighlightBox>Size Distr. Ratio</HighlightBox>
                  </FormulaRow>
                  <FormulaRow condition={!!row.usingLastPeriods}>
                    <HighlightBox>Last Periods Sales</HighlightBox>
                    <Operator operation='multiplication' />
                    <HighlightBox>Size Distr. Ratio</HighlightBox>
                  </FormulaRow>

                  <FormulaRow condition={!row.usingLastPeriods}>
                    <Parentheses>
                      <HighlightBox>{row.productionSales}</HighlightBox>
                      <Operator operation='addition' />
                      <HighlightBox>{row.restockingSales}</HighlightBox>
                    </Parentheses>
                    <Operator operation='multiplication' />
                    <HighlightBox>{float(lastYearGrowthRate)}</HighlightBox>
                    <Operator operation='multiplication' />
                    <HighlightBox>{float(row.sizeRatio)}</HighlightBox>
                  </FormulaRow>
                  <FormulaRow condition={!!row.usingLastPeriods}>
                    <HighlightBox>{row.lastPeriodsSales}</HighlightBox>
                    <Operator operation='multiplication' />
                    <HighlightBox>{row.sizeRatio}</HighlightBox>
                  </FormulaRow>

                  <FormulaRow>
                    <HighlightBox>{float((row.productionSales + row.restockingSales) * lastYearGrowthRate * row.sizeRatio).toString()}</HighlightBox>
                  </FormulaRow>
                  </Stack>}>
                  <Box component="span">{row.usingLastPeriods ? row.expectedLastPeriodsSales : row.expectedProductionSales + row.expectedRestockingSales}</Box>
                </NoMaxWidthTooltip>
              </TableCell>
              <TableCell align="right" sx={{color: rowColor}}>
                <NoMaxWidthTooltip arrow title={
                  <Stack direction={'column'} maxWidth={'100%'} gap={1}>
                    <FormulaRow>
                      <Parentheses title={'MAX'}>
                        <Parentheses title={'CEIL'}>
                          <Parentheses>
                            <HighlightBox>Expected Sales</HighlightBox>
                            <Operator operation='subtraction' />
                            <Parentheses>
                              <HighlightBox>Current Qty</HighlightBox>
                              <Operator operation='subtraction' />
                              <Box>2</Box>
                            </Parentheses>
                            <Operator operation='subtraction' />
                            <HighlightBox>Incoming Qty</HighlightBox>
                          </Parentheses>
                          <Operator operation='division' />
                          <Box>2</Box>
                        </Parentheses>
                        <Operator operation='multiplication' />
                        <Box>2,</Box>
                        <Box>0</Box>
                      </Parentheses>
                    </FormulaRow>
                    <FormulaRow>
                      <Parentheses title={'MAX'}>
                        <Parentheses title={'CEIL'}>
                          <Parentheses>
                            <HighlightBox>{row.usingLastPeriods ? row.expectedLastPeriodsSales : row.expectedProductionSales + row.expectedRestockingSales}</HighlightBox>
                            <Operator operation='subtraction' />
                            <Parentheses>
                              <HighlightBox>{row.available}</HighlightBox>
                              <Operator operation='subtraction' />
                              <Box>2</Box>
                            </Parentheses>
                            <Operator operation='subtraction' />
                            <HighlightBox>{row.incoming}</HighlightBox>
                          </Parentheses>
                          <Operator operation='division' />
                          <Box>2</Box>
                        </Parentheses>
                        <Operator operation='multiplication' />
                        <Box>2,</Box>
                        <Box>0</Box>
                      </Parentheses>
                    </FormulaRow>
                    <FormulaRow>
                      <Parentheses title={'MAX'}>
                        <Parentheses title={'CEIL'}>
                          <Parentheses>
                            <HighlightBox>{row.usingLastPeriods ? row.expectedLastPeriodsSales : row.expectedProductionSales + row.expectedRestockingSales}</HighlightBox>
                            <Operator operation='subtraction' />
                            <HighlightBox>{row.available - 2}</HighlightBox>
                            <Operator operation='subtraction' />
                            <HighlightBox>{row.incoming}</HighlightBox>
                          </Parentheses>
                          <Operator operation='division' />
                          <Box>2</Box>
                        </Parentheses>
                        <Operator operation='multiplication' />
                        <Box>2,</Box>
                        <Box>0</Box>
                      </Parentheses>
                    </FormulaRow>
                    <FormulaRow>
                      <Parentheses title={'MAX'}>
                        <Parentheses title={'CEIL'}>
                          <HighlightBox>{(row.usingLastPeriods ? row.expectedLastPeriodsSales : row.expectedProductionSales + row.expectedRestockingSales) - (row.available - 2) - row.incoming}</HighlightBox>
                          <Operator operation='division' />
                          <Box>2</Box>
                        </Parentheses>
                        <Operator operation='multiplication' />
                        <Box>2,</Box>
                        <Box>0</Box>
                      </Parentheses>
                    </FormulaRow>
                    <FormulaRow>
                      <Parentheses title={'MAX'}>
                        <Parentheses title={'CEIL'}>
                          <HighlightBox>{float(((row.usingLastPeriods ? row.expectedLastPeriodsSales : row.expectedProductionSales + row.expectedRestockingSales) - (row.available - 2) - row.incoming)/2).toString()}</HighlightBox>
                        </Parentheses>
                        <Operator operation='multiplication' />
                        <Box>2,</Box>
                        <Box>0</Box>
                      </Parentheses>
                    </FormulaRow>
                    <FormulaRow>
                      <Parentheses title={'MAX'}>
                        <HighlightBox>{Math.ceil(((row.usingLastPeriods ? row.expectedLastPeriodsSales : row.expectedProductionSales + row.expectedRestockingSales) - (row.available - 2) - row.incoming)/2)}</HighlightBox>
                        <Operator operation='multiplication' />
                        <Box>2,</Box>
                        <Box>0</Box>
                      </Parentheses>
                    </FormulaRow>
                    <FormulaRow>
                      <Parentheses title={'MAX'}>
                        <HighlightBox>{Math.ceil(((row.usingLastPeriods ? row.expectedLastPeriodsSales : row.expectedProductionSales + row.expectedRestockingSales) - (row.available - 2) - row.incoming)/2)*2}</HighlightBox>
                        <Box>,</Box>
                        <Box>0</Box>
                      </Parentheses>
                    </FormulaRow>
                    <FormulaRow>
                      <HighlightBox>{Math.max(Math.ceil(((row.usingLastPeriods ? row.expectedLastPeriodsSales : row.expectedProductionSales + row.expectedRestockingSales) - (row.available - 2) - row.incoming)/2)*2, 0)}</HighlightBox>
                    </FormulaRow>
                  </Stack>}>
                  <Box component="span">{row.required}</Box>
                </NoMaxWidthTooltip>
              </TableCell>
              <TableCell align="right" sx={{color: rowColor, paddingRight: '6px', paddingLeft: "6px"}}>
                <DebouncedTextField 
                  key={row.sku}
                  sku={row.sku}
                  value={localCustomRequired[row.sku]}
                  onInputChange={handleInputChange}
                />
              </TableCell>
              <TableCell align="right" sx={{fontWeight: "bold", color: rowColor}}>{row.order}</TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
  ) : "Loading"
}

const DebouncedTextField = ({ sku, value, onInputChange }) => {

  return (
    <TextField
      id={`text-field-${sku}`}
      value={value ?? ''}
      variant={'outlined'}
      size="small"
      sx={{width:'54px'}}
      inputProps={{inputMode: 'numeric', pattern: '[0-9]*', style: {textAlign: 'right', padding:"4px 10px 6px 10px", fontSize: "0.875rem"}}}
      onChange={(evt) => {
        const inputValue = evt.target.value;

        // Update intermediate state (e.g., '' for empty input)
        if (inputValue === "" || /^[0-9]*$/.test(inputValue)) {
          onInputChange(sku, inputValue); // Update local state
        }
      }}
      onKeyDown={(evt) => {
        if (evt.key === "Enter") {
          evt.preventDefault(); // Prevent the default action (if any)
          // Get all focusable elements
          const focusableElements = Array.from(document.querySelectorAll(
            'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
          ));
          // Get the currently focused element
          const currentFocus = document.activeElement;
          // Find the next focusable element
          const currentIndex = focusableElements.indexOf(currentFocus);
          const nextIndex = (currentIndex + 1) % focusableElements.length;
          // Focus the next element
          focusableElements[nextIndex].focus();
        }
      }}
    />
  )
}

const FormulaRow = ({
  children,
  condition = true,
}) => {
  if (!condition) {
    return null;
  }

  return (
    <Stack direction={'row'} fontSize='12' textAlign={'center'} alignItems={'center'} gap={1}>
      <Box>=</Box>
      {children}
    </Stack>
  )
}

const Parentheses = ({
  children,
  title
}) => {
  return (
    <>
    <Box>
      {title}
      &#40;
    </Box>
    {children}
    <Box>
      &#41;
    </Box>
    </>
  )
}

/**
 * @typedef {"addition" | "subtraction" | "multiplication" | "division"} OperationType
 */

/**
 * Operator Component
 * @param {Object} props
 * @param {OperationType} [props.operation="addition"] - The mathematical operation to display.
 */
const Operator = ({
  children,
  operation = 'addition',
}) => {
  let operator;

  switch (operation) {
    case "addition":
      operator = "\u002b";
      break;
    case "subtraction":
      operator = "\u2212";
      break;
    case "multiplication":
      operator = "\u00d7";
      break;
    case "division":
      operator = "\u00F7";
      break;
    default:
      operator = operation;
  }
  return (
    <Box>
      {operator}
    </Box>
  )
}

const HighlightBox = ({
  children
}) => {
  return (
    <Box bgcolor={'#666'} borderRadius={0.5} px={0.5} py={0.3}>
      <Stack direction='row' gap={0.5} alignItems={'center'}>
        {children}
      </Stack>
    </Box>
  )
}

export default StatsTable;