const names = {
    "CORRIDA_NOIR": "Corrida Black",
    "CORRIDA_BLANC": "Corrida White",
    "SD-BLK": "Slim Dança Black",
    "SD-WHI": "Slim Dança White",
    "CORRIDALTA_NOIRE": "Corridalta Black",
    "CORRIDALTA_BLANC": "Corridalta White",
    "MD-BLK": "Montante Black",
    "MD-WHI": "Montante White",
    "CF-BLK": "Crossfeet Black/White Sole",
    "URBANO_NOIR_BLANC": "Urbano Black/White",
    "URBANO_BLANC": "Urbano White",
    "URBANO_NOIR": "Urbano Black/Black Sole",
    "COC-BLK": "Corrida Black",
    "COC-WHI": "Corrida White",
    "CAC-BLK": "Corridalta Black",
    "CAC-WHI": "Corridalta White",
    "SLD-BLK": "Slim Dança Black",
    "SLD-WHI": "Slim Dança White",
    "URU-BLK": "Urbano Black",
    "URU-WHI": "Urbano White",
    "MND-BLK": "Mid-Top Dança Black",
    "UMU-BLK": "Urbano Mid-Top Black",
    "UMU-WHI": "Urbano Mid-Top White",
    "IN-PU": "Anatomic Insole PU",
    "IN-EVA": "Anatomic Insole EVA",
}

const getName = (sku) => {
    return names[sku.replace(/-\d+/, '')] ?? "";
}

export default getName;